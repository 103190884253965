import { ValueFormatterParams } from "ag-grid-enterprise"

export const BRAND_NAME = "ExtremeCloud Universal ZTNA"
export const REFRESH_INTERVAL_TEXT = "Data refreshes every 30 seconds"
export const COMPANY_NAME = "Extreme Networks"
export const APP_MARGIN = 16
export const APP_LAYOUT_GRID_ROW_GAP = 16
export const TOP_NAV_HEIGHT = 65
export const DRAWER_WIDTH = 255
export const CLOSED_DRAWER_WIDTH = 40
export const IP_ADDRESS_REGEX = /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/
export const CIDR_NOTATION_REGEX =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))?$/
export const IPV6_REGEX = /^([0-9a-f]){1,4}(:([0-9a-f]){1,4}){7}$/i
export const IPV6_CIDR_NOTATION_REGEX =
  /^([0-9a-fA-F]{1,4}(:[0-9a-fA-F]{1,4})*)|((([0-9a-fA-F]{1,4}:){1,7}|:)(:[0-9a-fA-F]{1,4})*)?(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$/i
export const IP_ADDRESS_OR_HOSTNAME_REGEX =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$/
export const IDP_DOMAIN_REGEX =
  /^@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]$/
export const AWS_ACCOUNT_ID_REGEX = /^[0-9]*$/
export const AZURE_SUBSCRIPTION_ID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
export const DEFAULT_ERROR_MESSAGE =
  "An unexpected error occurred on the server side. Please try again. If the error persists contact the support team!"
export const UNABLE_TO_FETCH = "We are unable to fetch the data. Please try again later"
export const UNABLE_TO_HANDLE_DATA =
  "We are unable to process the data. Please contact your support team for further assistance."
export const DEFAULT_CANCEL_MESSAGE = "Api Request Cancelled"
export const DEFAULT_EMPTY_SEARCH_RESULTS = "Your search produced no results."
export const DEFAULT_EMPTY_FILTER_RESULTS = "Your filter produced no results."
export const DEFAULT_EMPTY_SEARCH_AND_FILTER_RESULTS = "Your search or filter produced no results."
export const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&.])[A-Za-z\d@$!%*#?&.]{8,}$/
export const VERSION_REGEX = /^[1-9]\d*(\.\d+)\d*(\.\d+)?$/
export const PORT_REGEX = /^(?:0|[1-9]\d{0,3}|[1-5]\d{4}|6[0-3]\d{3}|64[0-2]\d{2}|643[0-4]\d|6435[0-4]|64355)$/
export const NETWORK_PORT_REGEX = /^\d+(-\d+)?(?:,\d+(-\d+)?)*$/
export const NETWORK_PORT_NUMERIC_REGEX = /^[\d,-]+$/
export const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9-]*$/
export const ALPHA_NUM_WO_HYP_REGEX = /^[a-zA-Z0-9]*$/
export const GCP_CONNECTOR_NAME_REGEX = /^[a-z][a-z0-9]*(-[a-z0-9]+)*$/
export const SPACE_CHECK_REGEX = /^\s*\S+.*/
export const WHITE_SPACE_CHECK = /\s/
export const ALPHANUMERIC_SPACE_HYPHEN_REGEX = /^([a-zA-Z0-9]+[_\s'-]{1})*[a-zA-Z0-9]+$/
export const SAAS_APP_URL_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/
export const URL_WITH_PROTOCOL_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/
export const DOMAIN_NAME_REGEX = /^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/

export const XML_FORMAT_REGEX = /<\/?([a-z][^>\s]*)\/?>?/gi

export const isInvalidIPAddress = (ip: string) => {
  const octets = ip.split(".").map(Number)
  // Wildcard address
  if (octets.join(".") === "0.0.0.0") return true

  // Broadcast address
  if (octets.join(".") === "255.255.255.255") return true

  // APIPA address range
  if (octets[0] === 169 && octets[1] === 254 && octets[2] === 0 && octets[3] >= 1 && octets[3] <= 254) {
    return true
  }

  return false
}

export enum Protocols {
  HTTP = "HTTP",
  HTTPS = "HTTPS",
  RDP = "RDP",
  VNC = "VNC",
  SSH = "SSH",
  TELNET = "TELNET",
  TCP = "TCP",
  UDP = "UDP",
  CLOUD_HOSTED = "CLOUD_HOSTED",
}

export enum Tabs {
  TERMINAL_ACCESS = "Terminal Access",
  REMOTE_DESKTOP = "Remote Desktop",
  PRIVATE_WEB = "Private Web",
  CUSTOM = "Custom App",
  MULTI_CLOUD_WEB = "Multi-Cloud Web",
}

export const ApplicationTypes = {
  PRIVATE_WEB_APP: "Private Web App",
  MULTI_CLOUD_WEB_APP: "Multi-Cloud Web App",
  TERMINAL_ACCESS: "Terminal Access",
  REMOTE_DESKTOP: "Remote Desktop",
  CUSTOM_APP: "Custom App",
} as const

export const mapProtocolToServiceName: Record<Protocols, Tabs> = {
  [Protocols.SSH]: Tabs.TERMINAL_ACCESS,
  [Protocols.TELNET]: Tabs.TERMINAL_ACCESS,
  [Protocols.VNC]: Tabs.REMOTE_DESKTOP,
  [Protocols.RDP]: Tabs.REMOTE_DESKTOP,
  [Protocols.HTTP]: Tabs.PRIVATE_WEB,
  [Protocols.HTTPS]: Tabs.PRIVATE_WEB,
  [Protocols.TCP]: Tabs.CUSTOM,
  [Protocols.UDP]: Tabs.CUSTOM,
  [Protocols.CLOUD_HOSTED]: Tabs.MULTI_CLOUD_WEB,
}

export const WEB_ACCESSIBLE_PROTOCOLS = [Protocols.HTTP, Protocols.HTTPS] // Other name = AGENTLESS_PROTOCOLS
export const TERMINAL_ACCESSIBLE_PROTOCOLS = [Protocols.SSH, Protocols.TELNET]
export const WEB_NON_ACCESSIBLE_PROTOCOLS = [Protocols.RDP, Protocols.VNC]
const CUSTOM_PROTOCOLS = [Protocols.TCP, Protocols.UDP]
export const ALL_PROTOCOLS = [
  ...WEB_ACCESSIBLE_PROTOCOLS,
  ...WEB_NON_ACCESSIBLE_PROTOCOLS,
  ...TERMINAL_ACCESSIBLE_PROTOCOLS,
  ...CUSTOM_PROTOCOLS,
]
export const APPLICATION_PROTOCOLS_LIST = ALL_PROTOCOLS.map((protocol) => ({ value: protocol, label: protocol }))

export const devicePostureMatchingCriteria = [
  { label: "Allow when all match", value: "ALL_MATCH" },
  { label: "Allow when some match", value: "SOME_MATCH" },
]

export const devicePostureCheckOperator = [
  { label: "Greater than", value: ">" },
  { label: "Greater than or equal to", value: ">=" },
  { label: "Less than", value: "<" },
  { label: "Less than or equal to", value: "<=" },
  { label: "Equal to", value: "==" },
]

export const LB_HOSTING_PROVIDER_MAP = {
  AWS: "aws",
}

export const regionMap: { [regionCode: string]: string } = {
  "us-east-2": "Ohio (US East)",
  "us-east-1": "N. Virginia (US East)",
  "us-west-1": "N. California (US West)",
  "us-west-2": "Oregon (US West)",
  "eu-central-1": "Frankfurt (Europe)",
  "eu-west-1": "Ireland (Europe)",
  "eu-west-2": "London (Europe)",
  "ap-northeast-2": "Seoul (Asia Pacific)",
  "ap-northeast-1": "Tokyo (Asia Pacific)",
  "ap-southeast-1": "Singapore (Asia Pacific)",
  "ap-southeast-2": "Sydney (Asia Pacific)",
  "": "N/A",
}

export const valueFormatter = (params: ValueFormatterParams, fallback?: string) =>
  params?.data ? params?.value || fallback || "N/A" : ""

export const THREE_MINUTES_IN_MILLISECONDS = 180000

export const ASTERISKS = "********"

export const UAA = "agentless_portal"

export const FEATURE_FLAGS_LOCAL_STORAGE_KEY = "featureFlags"

// export const ZTNA_SOCKET_URL = "https://websocket.extremecloudztna.com/ws"

export enum IdentityProviders {
  Azure = "Azure",
  GSuite = "GSuite",
  OnPremAzure = "OnPremAzure",
}

// Data Grid
export const INFINITE_SCROLL_PAGE_SIZE = 50
export const DATA_GRID_POLLING_INTERVAL = 30000
export const MAX_CACHE_BLOCKS = 5
export const BLOCK_LOAD_DEBOUNCE_MILLISECONDS = 1000

// RadSec Proxy Listing
export const FORCE_REMOVE = "force-remove"
export const REMOVE_WITH_DISCLAIMER = "remove-proxy-with-disclaimer"
export const PORT_NOT_REQUIRED_PROTOCOLS = ["ICMP", "ANY"]
