import { GridApi } from "ag-grid-enterprise"
import { ENHeading } from "en-react/dist/src/components/Heading"
import ZtnaIcon, { IconNameType } from "src/shared/components/Icons/ZtnaIcon"
import theme from "src/theme"
import { useDataGridStyles } from "./DataGrid.styles"

export type BlankSlateProps = {
  iconName: IconNameType
  slateHeading: string
  text?: string
  height?: number | string
  width?: number
  iconHeight?: string
  iconWidth?: string
}

export const DataGridBlankSlate = ({
  iconName,
  iconHeight,
  iconWidth,
  height,
  slateHeading,
  width,
  text,
  api,
}: BlankSlateProps & { api?: GridApi<any>; context?: any; containerClass?: string }) => {
  const classes = useDataGridStyles()
  // Remove one empty row when no rows overlay is shown
  api?.setRowCount(0)
  return (
    <div className={classes.blankSlate}>
      <div data-testid="blankSlate-icon" className={classes.blankSlateIcon}>
        <ZtnaIcon
          name={iconName}
          width={iconWidth}
          height={iconHeight}
          size="xl"
          style={{ color: theme.color.neutralLight[16] }}
        />
      </div>

      <ENHeading isBold variant="sm">
        {slateHeading}
      </ENHeading>

      <div className={classes.blankSlateText} style={{ maxWidth: width || 350 }}>
        {text && <span className={classes.textSpan}>{text}</span>}
      </div>
    </div>
  )
}
